import type { UserAffiliateInfo } from "@/types/affiliate";
import { UserPlan } from "./planTypes";
import { Order } from "@/types/orders";
import {
	UserAccountTypes,
	UserAccountUse,
	UserDepartments,
	UserNumberOfEmployees,
	UserOrganizationType,
} from "@/types/user";
import { UserOnlineStatus } from "@/types/chatTypes";

export * from "./businessTypes";
export * from "./planTypes";
export * from "./chatTypes";
export * from "./adminTypes";

export interface LoginUser {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
}

export enum SSO {
	google = "Google",
	apple = "Apple",
}

export enum OnboardingStages {
	SSO_INITIAL = "SSO_INITIAL",
	SIGNUP_COMPLETE = "SIGNUP_COMPLETE",
	ACC_VERIFIED = "ACC_VERIFIED",
	PLAN_PURCHASED = "PLAN_PURCHASED",
}

export type User = {
	_id: string;
	email: string;
	role: string;
	firstName: string;
	lastName: string;
	accountType: UserAccountTypes;
	companyName?: string;
	designation?: string;
	department?: UserDepartments;
	organizationType?: UserOrganizationType;
	accountUse?: UserAccountUse;
	numberOfEmployees?: UserNumberOfEmployees;
	mobileNumber: string;
	website?: string;
	avatar?: string;
	onboardingStages: OnboardingStages;
	plan: { duration: UserPlan; planID: string };
	createdAt: string;
	bio: string;
	onlineStatus: UserOnlineStatus;
	city?: string;
	state?: string;
	country?: string;
	isConsentAccepted: boolean;

	affiliatedBy: string | null;
	affiliateInfo: UserAffiliateInfo;
};

export type UserNote = {
	id?: string;
	userId: string;
	note: string;
	createdAt?: string;
};

export type UpdateUser = Partial<Omit<User, "id" | "_id" | "password" | "createdAt">>;
export type SignUpDTO = Omit<
	User,
	| "id"
	| "_id"
	| "plan"
	| "onboardingStages"
	| "affiliateInfo"
	| "role"
	| "isConsentAccepted"
	| "createdAt"
	| "onlineStatus"
> & {
	password: string;
};

export type Notification = {
	_id?: string;
	user: User;
	redirectPath: string;
	isRead: boolean;
	content: string;
};

export type FAQ = {
	_id?: string;
	question: string;
	answer: string;
};

export interface Review {
	userId: {
		_id: string;
		username: string;
		email: string;
		firstName: string;
		lastName: string;
		profilecpic: string;
	};
	orderId: Order;
	comment: string;
	rating: number;
	description: string;
	recommended: boolean;
	createdAt: string;
	updatedAt: string;
	_id?: string;
}

export type ErrorResponse = {
	error: string;
	message: string[];
	statusCode: number;
};

export enum DataStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INCATIVE",
	DELETED = "DELETED",
}

export enum ServiceStatus {
	DRAFT = "DRAFT",
	PUBLISHED = "PUBLISHED",
	ARCHIVED = "ARCHIVED",
}

export interface CookieConsentData {
	required: boolean;
	performance: boolean;
	advertisements: boolean;
}
